import React from "react";
import Dropdown from "../components/Common/Dropdown";
import Card from "../components/Card";

function Home() {
  return (
    <div>
      <Card />
    </div>
  );
}

export default Home;
