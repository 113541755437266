import { Navigate, Outlet } from "react-router-dom";
import Header from "../components/Header";

const ProtectedRoutesOutlet = () => {
  // const auth = { token: localStorage.getItem('token') };
  const auth = { token: "Authenticated" };
  return auth.token ? (
    <>
      {/* Header | Footer | Drawer for Protected Routes add here */}
      <header className="App-header fixed top-0 left-0 right-0 z-10">
        <Header />
      </header>
      <Outlet />
    </>
  ) : (
    <Navigate to="/login" />
  );
};

export default ProtectedRoutesOutlet;
