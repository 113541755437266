import ChatPage from "../pages/ChatPage";
import Home from "../pages/Home";

const ProtectedRotues = [
    {
      path: "/",
      element: <Home />
    },
    {
      path: "/bots/:botId",
      element: <ChatPage />
    }
  ]

export default ProtectedRotues;