import React from 'react'
import Card from '../components/Card'
import { useParams } from 'react-router-dom';

function ChatPage() {

    const { botId } = useParams();
  return (
    <div>
        <Card isChatPage={true} botName={botId} />
    </div>
  )
}

export default ChatPage