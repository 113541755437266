import axios from 'axios';
// /text-chat-elon
// /text-chat-indra
// /speech-chat-elon
// /speech-chat-indra

// Make a GET request to the /speech-chat endpoint

// ELON MUSK ENDPOINTS
const fetchSpeechChat = async (params) => {
  try {
    const response = await axios.post('/speech-chat', params, {
        headers: {
          "content-type": "multipart/form-data",
        },
      });
    return response;
  } catch (error) {
    console.error('There was a problem with the request:', error);
  }
};

// Make a GET request to the /text-chat endpoint
const fetchTextChat = async (params) => {
  try {
    const response = await axios.post('/text-chat', params);
    return response;
  } catch (error) {
    console.error('There was a problem with the request:', error);
  }
};

export {
    fetchSpeechChat,
    fetchTextChat,
}