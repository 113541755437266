import React from 'react';

const Tooltip = ({ children, message }) => {
  return (
    <div className="relative z-20 flex items-center group">
      {children}
      <div className="absolute bottom-full mb-2 hidden group-hover:flex w-max max-w-xs px-3 py-1 bg-gray-800 text-white text-sm rounded">
        {message}
      </div>
    </div>
  );
};

export default Tooltip;
