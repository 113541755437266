import React, { useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { SendHorizonal } from "lucide-react";
import { app_url } from "../../utils/services";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    borderRadius: "10px", // Adjust the value as needed (e.g., '10px')
    border: state.isFocused ? "1px solid #ccc" : "1px solid #ddd",
    display: "flex",
    alignItems: "center",
    background: "#0E46A3",
    height: "8rem",
  }),
  option: (provided, state) => ({
    ...provided,
    lineHeight: "20px", // Adjust the line height if needed
    padding: "5px 10px", // Adjust padding if needed
    whiteSpace: "normal",
    wordWrap: "break-word",
  }),
  menu: (provided) => ({
    ...provided,
    maxHeight: "200px", // Adjust the max height for dropdown (optional)
    overflowY: "auto", // Enable scrolling if needed
  }),
  SingleValue: (provided, state) => ({
    ...provided,
    color: "white",
    whiteSpace: "normal",
    wordWrap: "break-word",
  }),
};

const options = [
  {
    value: "elon_musk",
    label: "Elon Musk - American entrepreneur",
    imageUrl: `${app_url}/img/elon_musk.png`,
    width: "75px",
  },
  {
    value: "indra",
    label: "Indra Nooyi - Former Chairman and CEO of PepsiCo",
    imageUrl: `${app_url}/img/indra.png`,
    width: "75px",
  },
  {
    value: "serena",
    label: "Serena Williams - American former tennis player",
    imageUrl: `${app_url}/img/serena.png`,
    width: "75px",
  },
  {
    value: "modi",
    label: "Narendra Modi - Indian Prime Minister",
    imageUrl: `${app_url}/img/modi.png`,
    width: "75px",
  },
  {
    value: "newton",
    label: "Isaac Newton - English polymath",
    imageUrl: `${app_url}/img/newton.png`,
    width: "75px",
  },
];

const CustomSelect = () => {
  const [selectedOption, setSelectedOption] = useState({
    value: "elon_musk",
    label: "Elon Musk - American entrepreneur",
    imageUrl: `${app_url}/img/elon_musk.png`,
  });

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  return (
    <div className="flex gap-12 mt-6 flex-col justify-between">
      <Select
        className="w-[40vw]"
        styles={customStyles}
        value={selectedOption}
        onChange={handleChange}
        options={options}
        getOptionLabel={(option) => (
          <div className="flex flex-row align-middle">
            <img
              src={option.imageUrl}
              alt={option.label}
              style={{
                width:
                  option.value === "serena" ||
                  option.value === "modi" ||
                  option.value === "newton"
                    ? "50px"
                    : "75px",
                marginRight:
                  option.value === "serena" ||
                  option.value === "modi" ||
                  option.value === "newton"
                    ? "23px"
                    : "10px",
                marginLeft:
                  option.value === "serena" ||
                  option.value === "modi" ||
                  option.value === "newton"
                    ? "12px"
                    : "0px",
              }}
            />
            {option.label}
          </div>
        )}
        getOptionValue={(option) => option.value}
      />
      <Link to={`/bots/${selectedOption.value}`}>
        {/* <Button title="Start Chatting"/> */}
        <div className="flex justify-end" style={{ color: "#E1F7F5" }}>
          <SendHorizonal size={35} />
        </div>
      </Link>
    </div>
  );
};

export default CustomSelect;
