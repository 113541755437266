import Header from "./components/Header";
import ChatPage from "./pages/ChatPage";
import Home from "./pages/Home";
import {
  createBrowserRouter,
  Route,
  RouterProvider,
  Routes,
} from "react-router-dom";
import ProtectedRotues from "./routes/ProtectedRoutes";
import ProtectedRoutesOutlet from "./routes/ProtectedRotuesOutlet";
import { Toaster } from "react-hot-toast";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <p>404 Not Found!</p>,
  },
  {
    path: "/bots/:botId",
    element: <ChatPage />,
    errorElement: <p>404 Not Found!</p>,
  },
]);

function App() {
  return (
    <div className="App container mt-[5rem]">
      <Toaster
        position="top-right"
        containerClassName="hot-toaster-container"
      />
      <Routes>
        <Route element={<ProtectedRoutesOutlet />}>
          {ProtectedRotues?.map((route) => (
            <Route key={route.path} path={route.path} element={route.element} />
          ))}
        </Route>
        {/* <Route element={<PublicRoutesOutlet />}>
            {PublicRoutes?.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={route.component}
              />
            ))}
            <Route path="*" element={<PageNotFound />} />
          </Route> */}
        <Route path="*" element={<p>404 Not Found!</p>} />
      </Routes>

      <footer></footer>
    </div>
  );
}

export default App;
