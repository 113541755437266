import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";

function SimpleCard({ response }) {
  const [words, setWords] = useState([]);
  const containerRef = useRef(null);
  useEffect(() => {
    // Split the sentence into words
    if (response) {
      const wordsArray = response.split(" ");
      let isCancelled = false;

      // Function to update words with a delay
      const updateWordsWithDelay = async () => {
        for (let i = 0; i < wordsArray.length; i++) {
          if (isCancelled) break;
          await new Promise((resolve) => setTimeout(resolve, 200));
          setWords((prevWords) => [...prevWords, wordsArray[i]]);
        }
      };

      // Call the function to start animation
      updateWordsWithDelay();

      // Clean up function
      return () => {
        isCancelled = true;
        setWords([]);
      };
    }
  }, [response]);

  useEffect(() => {
    // Scroll to the bottom whenever content changes
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [words]);

  return (
    <>
      <div
        
        className="container shadow-md bg-white w-[40%] h-[30rem] xl:h-[29rem] rounded-md"
      >
        <h1 className="text-center shadow-lg text-base ai-response bg-white">
          Ai Response
        </h1>
        <p ref={containerRef} className="overflow-y-auto h-[25rem] overflow-auto px-6 pt-4">
          {!_.isEmpty(response) &&
            words.map((word, index) => <span key={index}>{word} </span>)}
        </p>
      </div>
    </>
  );
}

export default SimpleCard;
